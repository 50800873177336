import routerOptions0 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.14.0_jiti@2.4.0__ioredi_6ce7465f2813c17797bc62cd64b630f8/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/build/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}