export enum ENUM_PageName {
  Home = 'Home Page',
  List = 'List Page',
  Detail = 'Details Page',
  Checkout = 'Checkout Page', // 填写页
  BookingConfirmation = 'Booking Confirmation Page', // 支付完成页
  BookingList = 'Booking List Page', // 订单列表页
  BookingDetail = 'Booking Details Page', // 订单详情页
  Search = 'Search Page',
  SearchResultPage = 'Search Result Page',
  RankPage = 'Rank Page',
}

// export enum ENUM_TicketTypeId {
//   Adult = 1,
//   Child,
//   Family,
//   TicketOnly,
//   TicketMore,
// }

export enum ENUM_EventType {
  Search = 'search',
  SelectItem = 'select_item',
  SearchItem = 'search_item',
  DownloadButtonClick = 'download_button_clicks',
  BookItem = 'book_item',
  ViewItemList = 'view_item_list',
  ViewItem = 'view_item',
  RankClick = 'rank_clicks',
  CityClick = 'city_clicks',
  SearchDateClick = 'search_date_clicks',
  GuestNumberClick = 'guest_number_clicks',
  PageView = 'Page View',
  BeginCheckout = 'begin_checkout', // 填写页点击go to payment按钮触发
  Purchase = 'purchase', // 支付完成页 完成支付成功触发
  TabClicks = 'tab_clicks', // 首页金刚位点击
  TopCityClicks = 'top_city_clicks', // 首页常见城市点击，跳转榜单页
  RecentlyViewedClicks = 'recently_viewed_clicks', // 首页最近浏览点击
  RecClicks = 'rec_clicks', // 首页推荐点击
  TabSwitch = 'tab_switch', // 列表页和榜单页tab切换
  FilterOpen = 'filter_open', // 列表页筛选打开
  FilterSelect = 'filter_select', // 列表页筛选修改
  FilterApply = 'filter_apply', // 列表页筛选应用
  SortOpen = 'sort_open', // 列表页排序打开
  SortApply = 'sort_apply', // 列表页排序应用
}

export enum ENUM_DetailBtnType {
  BigBtn = 'fromprice_button',
  SmallBtn = 'product_button',
}

export enum ENUM_BAND_CODE {
  /**
   * 成人
   */
  ADULT = 'ADULT',
  /**
   * 儿童
   */
  CHILD = 'CHILD',
  /**
   * 婴儿
   */
  INFANT = 'INFANT',
  /**
   * 老人
   */
  SENIOR = 'SENIOR',
  /**
   * 其他
   */
  OTHER = 'OTHER',
  /**
   * 标准定价（不区分人群）
   */
  STANDARD = 'STANDARD',
  /**
   * 学生
   */
  STUDENT = 'STUDENT',
  /**
   * 优待人群（军人、教师、残疾人等）
   */
  PREFERENTIAL = 'PREFERENTIAL',
}

export enum E_RANK_TYPE {
  ALL = '1000',
  ATTRACTIONS = '1101',
  EXPERIENCE = '2000',
}

export enum E_RECOMMEND_CATEGORY {
  ALL = 'all',
  ATTR = 'att',
  EXPERIENCE = 'exp',
  TRANSPORT = 'pro-trans',
  MUST_HAVE = 'pro-must',
}

export enum E_CATEGORY_TYPE {
  ALL = 0,
  ATTRACTIONS = 464,
  EXPERIENCES = 465,
  TRANSPORT = 466,
  MUST_HAVES = 467,
  OTHERS = 468,
}

export enum ENUM_CARD_FROM {
  RECENT_VIEWED,
  HOME_RECOMMEND,
  SEARCH_RESULT,
  RANK_PAGE,
}
