<script setup lang="ts">
import 'vant/lib/index.css'

const { initLocale, locale, cookie, changeLocale } = useLocale()
initLocale()

useHead({
  script: [
    {
      src: useRuntimeConfig().public.NUXT_TRACK_SCRIPT_URL,
      async: true,
    },
  ],
})

const route = useRoute()
const isMobile = useMobileByScreen()
const commonStore = useCommonStore()

const { isBurnCoinsScene } = useGlobalStore()

setCanonical()

function setCanonical() {
  if (import.meta.client) {
    return
  }
  const url = useRequestURL()
  const origin = `https://${url.host}`
  let newMatch = route.matched[0].path
  Object.entries(route.params).forEach(([key, value]) => {
    if (key === 'locale') {
      return
    }
    newMatch = newMatch.replace(`:${key}()`, Array.isArray(value) ? value.join(',') : value)
  })
  const locales: string[] = useNuxtApp().payload.data?.locales || []
  useHead({
    link: [
      {
        rel: 'canonical',
        href: `${origin}${newMatch.replace(':locale()', locale.value)}`,
      },
      {
        rel: 'alternate',
        hreflang: 'en',
        href: `${origin}${newMatch.replace(':locale()', 'en-US')}`,
      },
      ...locales.map(item => ({
        rel: 'alternate',
        hreflang: item.toLowerCase(),
        href: `${origin}${newMatch.replace(':locale()', item)}`,
      })),
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${origin}${newMatch.replace(':locale()', 'en-US')}`,
      },
    ],
  })
}

// 如果cookie中没有locale，就设置分销商配置的默认locale
if (!cookie.value
  && commonStore.commonData.partnerInfo?.defaultLocale
  && commonStore.commonData.partnerInfo?.defaultLocale !== locale.value) {
  changeLocale(commonStore.commonData.partnerInfo?.defaultLocale)
}

// 根据路由判断当前页面的布局
const layout = computed(() => {
  if (route.path.startsWith('/hotel')) {
    return 'hotel'
  }
  if (route.path.startsWith('/mk_lp')) {
    return isMobile.value ? 'mobile' : 'desktop'
  }
  return isBurnCoinsScene ? 'coins' : 'default'
})

onMounted(() => {
  window.dispatchEvent(new Event('resize'))
})

watch(() => locale.value, () => {
  commonStore.updateInfoByLocale()
  commonStore.getCityList()
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtRouteAnnouncer />
  <NuxtLayout :name="layout">
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
:root:root {
  --van-popup-close-icon-color: #121212;
  --van-base-font: 'LexendDeca', sans-serif;
  --van-picker-option-padding: 0.625rem;
  --van-tag-radius: 0.25rem;
}
</style>
